import {Button, Layout, Space} from 'antd';
import {Route, Routes} from "react-router-dom";

import React from "react";

import '../css/custom.css';
import LeftSideMenu from "./navigation/LeftSideMenu";
import SystemUsersComponent from "./system/users/SystemUsersComponent";
import RolesComponent from "./system/users/RolesComponent";
import DashboardInsights from "./reports/DashboardInsights";

import RequireAuth from "../services/auth/RequireAuth";
import TransactionsListComponent from "./features/finance/TransactionsListComponent";
import UsersManagementComponent from "./features/users/UsersManagementComponent";
import AgentsListComponent from "./features/management/agents/AgentsListComponent";
import TeamsTopicsComponent from "./features/management/teams/TeamsTopicsComponent";
import NotificationTemplatesComponent from "./features/management/notications/NotificationTemplatesComponent";
import SmsGatewayManagementComponent from "./features/management/sms_gateways/SmsGatewayManagementComponent";
import CommissionsListComponent from "./features/commissions/CommissionsListComponent";
import {Header} from "antd/es/layout/layout";
import {getUserName} from "../state/auth/authStore";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {forceLogout} from "../services/auth/SessionHandler";
import BusinessesListComponent from "./features/businesses/BusinessesListComponent";
import BusinessesDetailsComponent from "./features/businesses/BusinessesDetailsComponent";
import UserDetailsComponent from "./features/users/UserDetailsComponent";
import SmsHistoryComponent from "./features/operations/SmsHistoryComponent";

const {Content} = Layout;

function MainLayout() {


    const username = getUserName();

    return (
        <Layout style={{minHeight: '100vh'}}>

            <LeftSideMenu></LeftSideMenu>

            <Layout className="site-layout" style={{marginLeft: 280}}>
                <Header style={{  background: '#f1f1f1', borderBottom:'1px solid #a3d1ee',display:'flex' , justifyContent: 'flex-end' }} >
                         <Space align="end" >
                             <UserOutlined/>
                              <span>{username}</span>
                             <Button onClick={forceLogout} icon={<LogoutOutlined/>} type="default">Logout</Button>
                         </Space>
                </Header>

                <Content style={{minHeight: '100vh', padding: '0 0'}}>

                    <Routes>
                        {/*<Route index element={<RolesComponent/>}/>*/}



                        <Route path="businesses">
                            <Route index element={<RequireAuth><BusinessesListComponent/></RequireAuth>}/>
                            <Route path=":businessId" element={<BusinessesDetailsComponent/>}/>
                        </Route>

                        <Route path="transactions" >
                            <Route index element={<RequireAuth><TransactionsListComponent/></RequireAuth>} />
                        </Route>


                        <Route path="operations/sms" >
                            <Route index element={<RequireAuth><SmsHistoryComponent/></RequireAuth>} />
                        </Route>
                        <Route path="operations/emails" >
                            <Route index element={<RequireAuth><TransactionsListComponent/></RequireAuth>} />
                        </Route>



                        <Route path="users">
                            <Route index element={<RequireAuth><UsersManagementComponent/></RequireAuth>}/>
                            <Route path=":userId" element={<RequireAuth><UserDetailsComponent/></RequireAuth>}/>
                        </Route>


                        <Route path="agents">
                            <Route index element={<RequireAuth><AgentsListComponent/></RequireAuth>}/>
                        </Route>

                        <Route path="commissions">
                            <Route index element={<RequireAuth><CommissionsListComponent/></RequireAuth>}/>
                        </Route>

                        <Route path="teams">
                            <Route index element={<RequireAuth><TeamsTopicsComponent/></RequireAuth>}/>
                        </Route>

                        <Route path="notifications">
                            <Route index element={<RequireAuth><NotificationTemplatesComponent/></RequireAuth>}/>
                        </Route>

                        <Route path="sms/gateways">
                            <Route index element={<RequireAuth><SmsGatewayManagementComponent/></RequireAuth>}/>
                        </Route>

                        <Route path="/users" element={<RequireAuth><SystemUsersComponent /></RequireAuth>}/>
                        <Route path="/roles" element={<RequireAuth><RolesComponent /></RequireAuth>}/>
                        <Route path="*" element={<RequireAuth><DashboardInsights/></RequireAuth>}/>
                    </Routes>

                </Content>
            </Layout>
        </Layout>
    );
}

export default MainLayout






