import {Card, Col, Divider, Row, Space, Spin, Table, Tag} from 'antd';
import React from 'react';
import {LoadingOutlined} from "@ant-design/icons";


interface StatsDetails  {
    icon: React.ReactNode,
    title: string,
    isLoading?: boolean,
    subTitle: string,
    textColor: string
}


const DashboardStatistic  = ({icon,title,subTitle,textColor,isLoading= false}: StatsDetails) => {

    return <>
        <Card
            hoverable={true}
            style={{border: 'solid #4361ee 1px '}}
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
             }}>

                {isLoading?<LoadingOutlined style={{ fontSize: 20 }} spin /> : icon}

                <div  style={{ backgroundColor:"transparent", textAlign:"end"}} >
                        <span style={{fontSize:"1em"}}>{title}</span><br/>
                        <span style={{ fontSize:"1.8em",color:`${textColor}` }}>{subTitle}</span>
                </div>


            </div>
        </Card>
    </>;


}

export default DashboardStatistic

