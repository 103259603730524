import {Card} from 'antd';
import React from "react";

function RolesComponent() {

    return (
        <Card>
            <p>Roles</p>
        </Card>
    );
}

export default RolesComponent






