import {Col, Row} from 'antd';

import React, {useState, useEffect} from 'react';
import DashboardStatistic from "./DashboardStatistic";
import {HiUserGroup} from "react-icons/hi";
import {AiOutlineUsergroupAdd} from "react-icons/ai";
import StatisticsGroup from "./StatisticsGroup";
import {getRequest} from "../../services/rest/RestService";
import {notifyHttpError} from "../../services/notification/notifications";
import {SubscriptionStats, TeamTopic} from "../../interfaces/MessagesInterfaces";
import {BsCashCoin, BsEnvelope} from "react-icons/bs";
import {getUserPermissions} from "../../state/auth/authStore";
import {MessageOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {BiBriefcase} from "react-icons/bi";

interface Stats {

    "activeSubscriptions": number,
    "dayTransactionsAmount": number,
    "weekTransactionsAmount": number,
    "monthTransactionsAmount": number,
    "allTimeTransactionsAmount": number,

    "daySubscriptionsCount": number,
    "weekSubscriptions": number,
    "monthSubscriptions": number,
    "allTimeSubscriptions": number,
    "daySmsCounter": number,
    "weekSmsCounter": number,
    "monthSmsCounter": number,
    "allTimeSmsCounter": number
}

interface SubscriptionsStats {
    "smsActiveSubscriptions": number,
    "smsDaySubscriptionsCount": number,
    "smsMonthSubscriptions": number,
    "smsAllTimeSubscriptions": number,
    "whatsAppActiveSubscriptions": number,
    "whatsAppDaySubscriptionsCount": number,
    "whatsAppMonthSubscriptions": number,
    "whatsAppAllTimeSubscriptions": number
}

interface NotificationsStats {
    "smsWeekNotifications": number,
    "smsDayNotifications": number,
    "smsMonthNotifications": number,
    "smsAllTimeNotifications": number,
    "whatsAppWeekNotifications": number,
    "whatsAppDayNotifications": number,
    "whatsAppMonthNotifications": number,
    "whatsAppAllTimeNotifications": number
}


const DashboardInsights = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isFinencier, setIsFinencier] = useState(false);
    const [stats, setStats] = useState<Stats>();
    const [subscsriptionStats, setSubscriptionsStats] = useState<SubscriptionsStats>();
    const [notificationsStats, setNotificationsStats] = useState<NotificationsStats>();
    const [teams, setTeams] = useState<TeamTopic[]>([]);
    const [subscriptionsByCode, setSubscriptionsByCode] = useState<SubscriptionStats[]>([]);

    const permissions: string[] = getUserPermissions();

    //Fetch products
    useEffect(() => {
        // fetchStats();
        // fetchSubscriptionsInsights();
        // fetchNotificationsInsights();
    }, []);

    //Fetch Stats
    const fetchStats = () => {
        console.log("Fetching stats...")
        setIsLoading(true)
        getRequest("/api/v1/businesses/reports/insights").then((response) => {
            setStats(response.data.respBody);
        }).catch((errorObj) => {
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const fetchSubscriptionsInsights = () => {
        console.log("Fetching subscription stats...")
        setIsLoading(true)
        getRequest("/api/v1/admin/businesses/reports/insights/businesses")
            .then((response) => {
                setSubscriptionsStats(response.data.respBody);
            }).catch((errorObj) => {
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const fetchNotificationsInsights = () => {
        console.log("Fetching notifications stats...")
        setIsLoading(true)
        getRequest("/api/v1/admin/businesses/reports/insights/notifications")
            .then((response) => {
                setNotificationsStats(response.data.respBody);
            }).catch((errorObj) => {
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }


    const isAllowed: boolean = permissions.includes("REPORTS_DASHBOARD")

    return <div style={{
        paddingLeft: '24px',
        paddingRight: '24px',
        marginTop: "24px",
        marginBottom: "64px",
    }}>


        <Row>
            <Col className="gutter-row" span={12}>
                {/**
                 -----------------------
                 | SMS Subscriptions Stats
                 -------------------------
                 */}
                {isAllowed && <StatisticsGroup
                    icon={<BiBriefcase style={{ fontSize: '24px', color:'blue'}}/>}
                    title="Businesses"
                    textColor="#4361ee">
                    <Row gutter={16}>

                        {/*Completed Orders Count*/}
                        {/*Active Subscribers*/}
                        <Col className="gutter-row" style={{marginTop:'8px'}} span={12} md={12} sm={24}>
                            <DashboardStatistic
                                textColor="#3c91e6"
                                isLoading={isLoading}
                                icon={<HiUserGroup
                                    color="#3c91e6"
                                    size={48}>
                                </HiUserGroup>}
                                title="Active Subscribers"
                                subTitle={`${new Intl.NumberFormat('en-US').format(subscsriptionStats?.smsActiveSubscriptions ?? 0)}`}
                            ></DashboardStatistic>
                        </Col>

                        {/*Day Subscribers*/}
                        <Col className="gutter-row" style={{marginTop:'8px'}} span={12} md={12} sm={24}>
                            <DashboardStatistic
                                textColor="#3c91e6"
                                isLoading={isLoading}
                                icon={<AiOutlineUsergroupAdd
                                    color="#3c91e6"
                                    size={48}>
                                </AiOutlineUsergroupAdd>}
                                title="Subscribed Today"
                                subTitle={`${new Intl.NumberFormat('en-US').format(subscsriptionStats?.smsDaySubscriptionsCount ?? 0)}`}
                            ></DashboardStatistic>
                        </Col>

                        {/*Month Subscribers*/}
                        <Col className="gutter-row" style={{marginTop:'8px'}} span={12} md={12} sm={24}>
                            <DashboardStatistic
                                textColor="#3c91e6"
                                isLoading={isLoading}
                                icon={<AiOutlineUsergroupAdd
                                    color="#3c91e6"
                                    size={48}>
                                </AiOutlineUsergroupAdd>}
                                title="This Month"
                                subTitle={`${new Intl.NumberFormat('en-US').format(subscsriptionStats?.smsMonthSubscriptions ?? 0)}`}
                            ></DashboardStatistic>
                        </Col>

                        {/*All Time Subscribers*/}
                        <Col className="gutter-row" style={{marginTop:'8px'}} span={12} md={12} sm={24}>
                            <DashboardStatistic
                                textColor="#3c91e6"
                                isLoading={isLoading}
                                icon={<AiOutlineUsergroupAdd
                                    color="#3c91e6"
                                    size={48}>
                                </AiOutlineUsergroupAdd>}
                                title="All Time"
                                subTitle={`${new Intl.NumberFormat('en-US').format(subscsriptionStats?.smsAllTimeSubscriptions ?? 0)}`}
                            ></DashboardStatistic>
                        </Col>

                    </Row>

                </StatisticsGroup>}
            </Col>

            <Col className="gutter-row" span={12}>
                {/**
                 -----------------------
                 | WhatsApp Subscription Stats
                 -------------------------
                 */}
                {isAllowed && <StatisticsGroup
                    icon={<WhatsAppOutlined style={{ fontSize: '24px', color:'green'}}/>}
                    title="Branches"
                    textColor="#29bf12">
                    <Row gutter={16}>

                        {/*Completed Orders Count*/}
                        {/*Active Subscribers*/}
                        <Col className="gutter-row" style={{marginTop:'8px'}} span={12} md={12} sm={24}>
                            <DashboardStatistic
                                textColor="#29bf12"
                                isLoading={isLoading}
                                icon={<HiUserGroup
                                    color="#29bf12"
                                    size={48}>
                                </HiUserGroup>}
                                title="Active Subscribers"
                                subTitle={`${new Intl.NumberFormat('en-US').format(subscsriptionStats?.whatsAppActiveSubscriptions ?? 0)}`}
                            ></DashboardStatistic>
                        </Col>

                        {/*Day Subscribers*/}
                        <Col className="gutter-row" style={{marginTop:'8px'}} span={12} md={12} sm={24}>
                            <DashboardStatistic
                                textColor="#29bf12"
                                isLoading={isLoading}
                                icon={<AiOutlineUsergroupAdd
                                    color="#29bf12"
                                    size={48}>
                                </AiOutlineUsergroupAdd>}
                                title="Subscribed Today"
                                subTitle={`${new Intl.NumberFormat('en-US').format(subscsriptionStats?.whatsAppDaySubscriptionsCount ?? 0)}`}
                            ></DashboardStatistic>
                        </Col>

                        {/*Month Subscribers*/}
                        <Col className="gutter-row" style={{marginTop:'8px'}} span={12} md={12} sm={24}>
                            <DashboardStatistic
                                textColor="#29bf12"
                                isLoading={isLoading}
                                icon={<AiOutlineUsergroupAdd
                                    color="#29bf12"
                                    size={48}>
                                </AiOutlineUsergroupAdd>}
                                title="This Month"
                                subTitle={`${new Intl.NumberFormat('en-US').format(subscsriptionStats?.whatsAppMonthSubscriptions ?? 0)}`}
                            ></DashboardStatistic>
                        </Col>

                        {/*All Time Subscribers*/}
                        <Col className="gutter-row" style={{marginTop:'8px'}} span={12} md={12} sm={24}>
                            <DashboardStatistic
                                textColor="#29bf12"
                                isLoading={isLoading}
                                icon={<AiOutlineUsergroupAdd
                                    color="#29bf12"
                                    size={48}>
                                </AiOutlineUsergroupAdd>}
                                title="All Time"
                                subTitle={`${new Intl.NumberFormat('en-US').format(subscsriptionStats?.whatsAppAllTimeSubscriptions ?? 0)}`}
                            ></DashboardStatistic>
                        </Col>

                    </Row>

                </StatisticsGroup>}
            </Col>
        </Row>





        {/**
         ------------------------
         | Transactions Stats
         ------------------------
         */}
        {isFinencier && <StatisticsGroup
            title="Users"
            textColor="#4361ee"
        >
            <Row gutter={16}>

                {/*Week Transactions amount*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        isLoading={isLoading}
                        textColor="#1e96fc"
                        icon={<BsCashCoin
                            color="#1e96fc"
                            size={32}>
                        </BsCashCoin>}
                        title="Day's Revenue"
                        subTitle={`${new Intl.NumberFormat('en-US').format(stats?.dayTransactionsAmount ?? 0)} TZS`}
                    ></DashboardStatistic>
                </Col>

                {/*Week Transactions amount*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        isLoading={isLoading}
                        textColor="#1e96fc"
                        icon={<BsCashCoin
                            color="#1e96fc"
                            size={32}>
                        </BsCashCoin>}
                        title="Week's Revenue"
                        subTitle={`${new Intl.NumberFormat('en-US').format(stats?.weekTransactionsAmount ?? 0)} Tsh`}
                    ></DashboardStatistic>
                </Col>

                {/*Month transactions amount*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#1e96fc"
                        isLoading={isLoading}
                        icon={<BsCashCoin
                            color="#1e96fc"
                            size={32}>
                        </BsCashCoin>}
                        title="Month's Revenue"
                        subTitle={`${new Intl.NumberFormat('en-US').format(stats?.monthTransactionsAmount ?? 0)} Tsh`}
                    ></DashboardStatistic>
                </Col>

                {/*All Transactions Count*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#1e96fc"
                        isLoading={isLoading}
                        icon={<BsCashCoin
                            color="#1e96fc"
                            size={32}>
                        </BsCashCoin>}
                        title="All Time Revenue"
                        subTitle={`${new Intl.NumberFormat('en-US').format(stats?.allTimeTransactionsAmount ?? 0)}`}
                    ></DashboardStatistic>
                </Col>

            </Row>
        </StatisticsGroup>}


        {/**
         ------------------------
         | SMS Counters
         ------------------------
         */}
        {isAllowed && <StatisticsGroup
            icon={<MessageOutlined style={{ fontSize: '24px', color:'blue'}}/>}
            title="Products"
            textColor="#4361ee"
        >
            <Row gutter={16}>

                {/*Day SMSes*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#3c91e6"
                        isLoading={isLoading}
                        icon={<BsEnvelope
                            color="#3c91e6"
                            size={32}>
                        </BsEnvelope>}
                        title="Sent Today"
                        subTitle={`${new Intl.NumberFormat('en-US').format(notificationsStats?.smsDayNotifications ?? 0)} SMS`}
                    ></DashboardStatistic>
                </Col>

                {/*Week SMSes*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#3c91e6"
                        isLoading={isLoading}
                        icon={<BsEnvelope
                            color="#3c91e6"
                            size={32}>
                        </BsEnvelope>}
                        title="Sent This Week"
                        subTitle={`${new Intl.NumberFormat('en-US').format(notificationsStats?.smsWeekNotifications ?? 0)} SMS`}
                    ></DashboardStatistic>
                </Col>

                {/*Month SMSs*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#3c91e6"
                        isLoading={isLoading}
                        icon={<BsEnvelope
                            color="#3c91e6"
                            size={32}>
                        </BsEnvelope>}
                        title="Sent This Month"
                        subTitle={`${new Intl.NumberFormat('en-US').format(notificationsStats?.smsMonthNotifications ?? 0)} SMS`}
                    ></DashboardStatistic>
                </Col>

                {/*Month SMSs*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#3c91e6"
                        isLoading={isLoading}
                        icon={<BsEnvelope
                            color="#3c91e6"
                            size={32}>
                        </BsEnvelope>}
                        title="Sent - All Time"
                        subTitle={`${new Intl.NumberFormat('en-US').format(notificationsStats?.smsAllTimeNotifications ?? 0)} SMS`}
                    ></DashboardStatistic>
                </Col>
            </Row>
        </StatisticsGroup>}



        {/**
         ------------------------
         | WhatAss Counters
         ------------------------
         */}
        {isAllowed && <StatisticsGroup
            icon={<WhatsAppOutlined style={{ fontSize: '24px', color:'green'}}/>}
            title="Orders"
            textColor="#29bf12"
        >
            <Row gutter={16}>

                {/*Day SMSes*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#29bf12"
                        isLoading={isLoading}
                        icon={<WhatsAppOutlined
                            color="green"
                            style={{ fontSize:'32px', color:'#29bf12'}}>
                        </WhatsAppOutlined>}
                        title="Sent Today"
                        subTitle={`${new Intl.NumberFormat('en-US').format(notificationsStats?.whatsAppDayNotifications ?? 0)} SMS`}
                    ></DashboardStatistic>
                </Col>

                {/*Week SMSes*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#29bf12"
                        isLoading={isLoading}
                        icon={<WhatsAppOutlined
                            color="green"
                            style={{ fontSize:'32px', color:'#29bf12'}}>
                        </WhatsAppOutlined>}
                        title="Sent This Week"
                        subTitle={`${new Intl.NumberFormat('en-US').format(notificationsStats?.whatsAppWeekNotifications ?? 0)} SMS`}
                    ></DashboardStatistic>
                </Col>

                {/*Month SMSs*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#29bf12"
                        isLoading={isLoading}
                        icon={<WhatsAppOutlined
                            color="green"
                            style={{ fontSize:'32px', color:'#29bf12'}}>
                        </WhatsAppOutlined>}
                        title="Sent This Month"
                        subTitle={`${new Intl.NumberFormat('en-US').format(notificationsStats?.whatsAppMonthNotifications ?? 0)} SMS`}
                    ></DashboardStatistic>
                </Col>

                {/*Month SMSs*/}
                <Col className="gutter-row" span={6}>
                    <DashboardStatistic
                        textColor="#29bf12"
                        isLoading={isLoading}
                        icon={<WhatsAppOutlined
                            color="green"
                            style={{ fontSize:'32px', color:'#29bf12'}}>
                        </WhatsAppOutlined>}
                        title="Sent - All Time"
                        subTitle={`${new Intl.NumberFormat('en-US').format(notificationsStats?.whatsAppAllTimeNotifications ?? 0)} SMS`}
                    ></DashboardStatistic>
                </Col>
            </Row>
        </StatisticsGroup>}

        {!isAllowed && <div>
            <h3> Hesabu Admin</h3>
            <p> Use left menu for navigation</p>
        </div>}


    </div>;

}

export default DashboardInsights

